import React, { useState, useEffect, useRef } from 'react';

const ProfileInfo = ({ userData }) => {
    const [newProfilePicture, setNewProfilePicture] = useState('');
    const [newBio, setNewBio] = useState('');

    useEffect(() => {
        if (userData) {
            setNewProfilePicture(userData.profilePicture);
            setNewBio(userData.bio);
        }
    }, [userData]);

    return (
        <div>
            {userData && (
                <div>
                    <img
                        src={newProfilePicture || userData.profilePicture}
                        alt="Profile"
                        style={{ cursor: 'pointer' }}
                    />

                    <h2>{userData.displayName}</h2>
                    {userData.bio}
                    <p>Number of Posts: {userData.numberOfPosts}</p>
                    <p>Date Signed Up: {userData.dateSignedUp}</p>
                </div>
            )}
        </div>
    );
};

export default ProfileInfo;
